import update from 'immutability-helper'
import { Event, ShoppingCart, Ticket, TicketState, ShoppingCartItemTicket, ProductState, FixedPriceProduct, RangePriceProduct } from '../../types/application-types'
import {
	ActionTypes,
	ChangeTicketActionType,
	ReserveTicketsActionType,
	SetActiveEventActionType,
	SetRehydratingActionType,
	SetRehydratingCheckoutActionType,
	SetShoppingCartActionType,
	ClearTicketsActionType,
	SetCreatingCheckoutActionType,
	SetProductsActionType,
	ReserveProductsActionType,
	SetCheckoutActionType,
	SetCheckoutExpiredActionType,
	ChangeProductStateActionType,
	AddCouponActionType,
	RemoveCouponActionType
} from './reducer-actions'
import { ApiCheckout, ApiCoupon } from '../../types/api-types'

type State = {
	creatingCheckout: boolean
  rehydrating: boolean
  rehydratingCheckout: boolean
	checkout: ApiCheckout | null
	checkoutExpired: boolean
  activeEvent: Event | null
  shoppingCart: ShoppingCart | null
	products: (FixedPriceProduct | RangePriceProduct)[]
	coupons: ApiCoupon[]
}

type Action =
  | ReserveTicketsActionType
	| ReserveProductsActionType
  | SetRehydratingActionType
  | SetActiveEventActionType
  | SetShoppingCartActionType
  | ChangeTicketActionType
	| ChangeProductStateActionType
  | SetRehydratingCheckoutActionType
  | ClearTicketsActionType
	| SetCreatingCheckoutActionType
	| SetCheckoutActionType
	| SetCheckoutExpiredActionType
	| SetProductsActionType
	| AddCouponActionType
	| RemoveCouponActionType

const reducer = (state: State, action: Action) => {
	switch (action.type) {
	case ActionTypes.SET_CREATING_CHECKOUT: {
		const { value } = (action as SetCreatingCheckoutActionType).payload
		return update(state, {
			creatingCheckout: { $set: value }
		})
	}
	case ActionTypes.SET_REHYDRATING: {
		const { value } = (action as SetRehydratingActionType).payload
		return update(state, {
			rehydrating: { $set: value }
		})
	}
	case ActionTypes.SET_REHYDRATING_CHECKOUT: {
		const { shoppingCart } = state
		const { value } = (action as SetRehydratingCheckoutActionType).payload
		if (!value && shoppingCart != null) {
			// Rehydrating done, fix tickets amount with shopping cart.
			return update(state, {
				activeEvent: {
					tickets: {
						$apply: (tickets: Ticket[]) => tickets.map((ticket: Ticket) => {
							const amount = shoppingCart.items[0] != null && shoppingCart.items[0].tickets != null ? shoppingCart.items[0].tickets?.filter((shoppingCartTicketItem: ShoppingCartItemTicket) => shoppingCartTicketItem.ticketTypeId === ticket.id).length : 0
							return {
								...ticket,
								amount
							}
						})
					}
				},
				rehydratingCheckout: { $set: value }
			})
		} else {
			return update(state, {
				rehydratingCheckout: { $set: value }
			})
		}
	}
	case ActionTypes.SET_ACTIVE_EVENT: {
		const { event } = (action as SetActiveEventActionType).payload
		return update(state, {
			activeEvent: { $set: event }
		})
	}
	case ActionTypes.SET_SHOPPING_CART: {
		const { shoppingCart, coupons } = (action as SetShoppingCartActionType).payload
		
		const updateObj: any = {
			shoppingCart: { $set: shoppingCart }
		}
	
		if (coupons !== undefined) {
			updateObj.coupons = { $set: coupons }
		}
	
		return update(state, updateObj)
	}
	case ActionTypes.CLEAR_TICKETS: {
		return update(state, {
			activeEvent: {
				tickets: {
					$apply: (tickets: Ticket[]) => tickets.map((ticket: Ticket) => ({
						...ticket,
						amount: 0,
						state: TicketState.INIT
					})) 
				}
			}
		})
	}
	case ActionTypes.RESERVE_TICKETS: {
		const { activeEvent, shoppingCart } = state
		const { ticketId, amount } = (action as ReserveTicketsActionType).payload
		const ticketIdx = activeEvent?.tickets.findIndex(
			({ id }) => id === ticketId
		)
		if (ticketIdx == null || ticketIdx === -1) return state
		return update(state, {
			activeEvent: {
				tickets: {
					[ticketIdx]: {
						amount: { $set: amount },
						state: {
							$set: shoppingCart
								? TicketState.UPDATING
								: TicketState.RESERVING
						}
					}
				}
			}
		})
	}
	case ActionTypes.RESERVE_PRODUCTS: {
		const { products, shoppingCart } = state
		const { productId, amount, price } = (action as ReserveProductsActionType).payload
		const priceUpdate = price ? { price: { $set: price } } : {}
		const productIdx = products.findIndex(({ _id }) => _id === productId)
		if (productIdx === -1) return state
		const newState = update(state, {
			products: {
				[productIdx]: {
					amount: { $set: amount },
					state: { 
						$set: shoppingCart 
							? ProductState.UPDATING 
							: ProductState.RESERVING
						},
					...priceUpdate
				}
			}
		})
		return newState
	}
	case ActionTypes.CHANGE_TICKET_STATE: {
		const { activeEvent } = state
		const { ticketId, state: ticketState, amountLeft } = (action as ChangeTicketActionType).payload
		const ticketIdx = activeEvent?.tickets.findIndex(({ id }) => id === ticketId)
		if (ticketIdx == null || ticketIdx === -1) return state
		const v = amountLeft ? { v: { $set: amountLeft } } : {}
		return update(state, {
			activeEvent: {
				tickets: {
					[ticketIdx]: {
						state: { $set: ticketState },
						...v
					}
				}
			}
		})
	}
	case ActionTypes.CHANGE_PRODUCT_STATE: {
		const { activeEvent, products, checkout } = state
		const { productId, state: productState } = (action as ChangeProductStateActionType).payload
		const productIdx = products.findIndex(({ _id }) => _id === productId)
		if (productIdx === -1) return state
		const checkoutItems = checkout!.items.find((checkoutItem) => checkoutItem.eventId === activeEvent!._id)
		const checkoutProduct = checkoutItems?.products.find((checkoutProduct) => checkoutProduct.productVariantId === products[productIdx].productVariantId)
		if (checkoutProduct) {
			return update(state, {
				products: {
					[productIdx]: {
						state: { $set: productState},
						amount: { $set: checkoutProduct!.amount },
						price: { $set: checkoutProduct!.price }
					}
				}
			})
		} else {
			return update(state, {
				products: {
					[productIdx]: {
						state: { $set: productState},
						amount: { $set: 0 }
					}
				}
			})
		}
	}
	case ActionTypes.SET_PRODUCTS: {
		const { products } = (action as SetProductsActionType).payload
		return update(state, {
			products: { $set: products }
		})
	}
	case ActionTypes.SET_CHECKOUT: {
		const { activeEvent, products } = state
		const { checkout } = (action as SetCheckoutActionType).payload

		if (checkout == null) {
			const updatedProducts = products.map((product) => ({
				...product,
				amount: 0,
				state: ProductState.INIT
			}))
			return update(state, {
				checkout: { $set: checkout },
				products: { $set: updatedProducts }
			})
		}

		const checkoutItems = checkout.items.find((checkoutItem) => checkoutItem.eventId === activeEvent!._id)
		const updatedProducts = products.map((product) => {
			const checkoutProduct = checkoutItems?.products.find((checkoutProduct) => checkoutProduct.productVariantId === product.productVariantId)
			if (checkoutProduct) {
				return {
					...product,
					amount: checkoutProduct.amount,
					state: ProductState.INIT
				}
			} else {
				return {
					...product,
					amount: 0,
					state: ProductState.INIT
				}
			}
		})
		return update(state, {
			checkout: { $set: checkout },
			products: { $set: updatedProducts }
		})
	}
	case ActionTypes.SET_CHECKOUT_EXPIRED: {
		const { checkoutExpired } = (action as SetCheckoutExpiredActionType).payload
		return update(state, {
			checkoutExpired: { $set: checkoutExpired }
		})
	}
	case ActionTypes.ADD_COUPON: {
		const { coupon } = (action as AddCouponActionType).payload
		return update(state, {
			coupons: { $push: [coupon] }
		})
	}
	case ActionTypes.REMOVE_COUPON: {
		const { couponId } = (action as RemoveCouponActionType).payload
		return update(state, {
			coupons: { $apply: (coupons: ApiCoupon[]) => coupons.filter(coupon => coupon.couponId !== couponId) }
		})
	}
	default:
		return state
	}
}

export default reducer
