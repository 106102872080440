import times from 'lodash/times'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ApplicationContextType } from './providers/application-provider'
import { createCheckout, bas, updateCheckout } from './api-handler'
import { Event, ProductState, Ticket, TicketState } from './types/application-types'
import { calculateAllowedAmountForOrRules, checkAndRulesAreSatisfied, mapShoppingCart } from './util'
import { ApiCheckoutItemTicket, ProductType } from './types/api-types'

export const useReserveHook = (event: Event | null, application: ApplicationContextType) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { activeEvent, checkout, coupons, products, shoppingCart, setShoppingCart, changeTicketState, changeProductState, setCreatingCheckout, setCheckout, setCheckoutExpired } = application

	const onBasRequest = async (
		eventId: string,
		ticketId: string,
		amount: number,
		checkoutId?: string,
		secret?: string,
		conditionalTickets?: Array<{ ticketTypeId: string, amount: number }>
	) => {
		const shopId = searchParams.get('underShop')
		const appliedCoupons = coupons.map(({ code }) => code)
		return bas(activeEvent!.seller._id, shopId, checkoutId, secret, eventId, ticketId, amount, appliedCoupons, conditionalTickets)
			.then((res) => {
				if (checkoutId == null) {
					// Add checkoutId and secret to the url.
					const newParams = new URLSearchParams(searchParams)
					newParams.set('checkoutId', res.data.checkout._id)
					newParams.set('secret', res.data.checkout.secret)
					setSearchParams(newParams)
				}
				setCheckout(res.data.checkout)
				const shoppingCart = mapShoppingCart(res.data.checkout)
				setShoppingCart(shoppingCart)
				changeTicketState(ticketId, TicketState.INIT)
			})
			.catch((error) => {
				if (error.response?.data?.error === 'NOT_ENOUGH_SEATS') {
					console.log(error.response.data.amountLeft)
					return changeTicketState(ticketId, TicketState.RESERVING_FAILED, error.response.data.amountLeft)
				}
				if (error.response?.data?.error === 'CHECKOUT_STATUS') {
					// This means the checkout is aborted or completed.
					setCheckoutExpired(true)
				}
				changeTicketState(ticketId, TicketState.RESERVING_FAILED)
			})
	}

	const onCreateCheckout = async (eventId: string, ticketId: string, amount: number, isNonSeatingTicket: boolean) => {
		const shopId = searchParams.get('underShop')
		
		if (isNonSeatingTicket) {
			return createCheckout(shopId, eventId, times(amount, () => ({ amount: 1, ticketTypeId: ticketId })), coupons.map(({ code }) => code))
				.then(async (res) => {
					// Add checkoutId and secret to the url.
					const newParams = new URLSearchParams(searchParams)
					newParams.set('checkoutId', res.data._id)
					newParams.set('secret', res.data.secret)
					setSearchParams(newParams)

					// if (!isNonSeatingTicket) {
					// 	await onBasRequest(eventId, ticketId, amount, res.data._id, res.data.secret)
					// }
					setCheckout(res.data)
					const shoppingCart = mapShoppingCart(res.data)
					setShoppingCart(shoppingCart)
					changeTicketState(ticketId, TicketState.INIT)
					setCreatingCheckout(false)
				})
				.catch((error) => {
					console.log(error.response.data)
					changeTicketState(ticketId, TicketState.RESERVING_FAILED)
				})
		} else {
			await onBasRequest(eventId, ticketId, amount)
			setCreatingCheckout(false)
		}
	}

	const handleReserve = async (eventId: string, tickets: Ticket[]) => {
		for (const ticket of tickets) {
			// Find category for this ticket
			const category = activeEvent?.categories.find(
				(cat) => cat.ref === activeEvent.tickets.find((t) => t.id === ticket.id)?.categoryRef
			)
			
			const isNonSeatingTicket = category?.listWithoutSeats === true
			
			if (ticket.state === TicketState.RESERVING) {
				setCreatingCheckout(true)
				await onCreateCheckout(eventId, ticket.id, ticket.amount, isNonSeatingTicket)
			} else if (ticket.state === TicketState.UPDATING) {
				if (shoppingCart) {
					if (isNonSeatingTicket) {
						// For non-seating tickets, update via handleTickets
						await handleTickets(checkout, tickets)
					} else {
						// For seating tickets, use BAS
						const { _id, secret } = shoppingCart

						// Find conditional tickets that need adjustment
						const conditionalTickets = activeEvent!.tickets
							.filter((t) => 
								t.conditionalAvailability && 
								t.amount > 0 && 
								t.id !== ticket.id
							)
							.map((t) => {
								const andRulesSatisfied = checkAndRulesAreSatisfied(activeEvent!, t)
								const allowedAmount = calculateAllowedAmountForOrRules(activeEvent!, t)
								
								if (!andRulesSatisfied) {
									// If AND rules are not satisfied, set amount to 0
									return {
										ticketTypeId: t.id,
										amount: 0
									}
								}
								
								if (allowedAmount !== null && t.amount > allowedAmount) {
									return {
										ticketTypeId: t.id,
										amount: allowedAmount
									}
								}
								return null
							})
							.filter((t): t is { ticketTypeId: string; amount: number } => t !== null)

						await onBasRequest(eventId, ticket.id, ticket.amount, _id, secret, conditionalTickets.length > 0 ? conditionalTickets : undefined)
					}
				}
			}
		}
	}

	// Also adjust conditional tickets here if necessary
	const handleTickets = async (checkout: any, tickets: Ticket[]) => {
    const { _id, secret, items } = checkout
		
    const itemForActiveEvent = items.find(({ eventId }: { eventId: string }) => activeEvent!._id === eventId)
    if (!itemForActiveEvent) {
			// We have a checkout, but removed all tickets/products and are now trying to add tickets of ticket type with list without seats.
			// We have no seating reservation token
			// We have no products (otherwise we would have itemForActiveEvent.products)
			const ticketCartItems = tickets.reduce((acc: any[], ticket: Ticket) => {
        if (ticket.amount === 0) return acc
				// No existing tickets - create new ones
				const newTickets = Array(ticket.amount).fill({
					name: ticket.name,
					price: ticket.price,
					taxRate: ticket.taxRate,
					categoryRef: ticket.categoryRef,
					ticketTypeId: ticket.id,
					amount: 1,
					type: 'ticket'
				})
				
				return [...acc, ...newTickets]
			}, [])
			const shopId = searchParams.get('underShop')
			return updateCheckout(
				_id, 
				secret, 
				activeEvent!._id, 
				ticketCartItems, 
				shopId ? shopId : undefined, 
				undefined, 
				coupons.map(({ code }) => code)
			).then((res) => {
				setCheckout(res.data)
				const shoppingCart = mapShoppingCart(res.data)
				setShoppingCart(shoppingCart)
				// Reset ticket state
				tickets.forEach((ticket) => {
					if (ticket.state === TicketState.UPDATING) {
						changeTicketState(ticket.id, TicketState.INIT)
					}
				})
			}).catch((error) => {
				if (error.response?.data?.message === 'Checkout is already in final state') {
					setCheckoutExpired(true)
				}
				tickets.forEach((ticket) => {
					if (ticket.state === TicketState.UPDATING) {
						changeTicketState(ticket.id, TicketState.RESERVING_FAILED)
					}
				})
			})
		} 
    
    const { shopId, seatingReservationToken, tickets: checkoutTickets, products: checkoutProducts } = itemForActiveEvent
    
    // Map tickets, handling multiple quantities correctly
    const ticketCartItems = tickets.reduce((acc: any[], ticket: Ticket) => {
        if (ticket.amount === 0) return acc
        
        const existingTickets = checkoutTickets.filter(
            (t: ApiCheckoutItemTicket) => t.ticketTypeId === ticket.id
        )
        
        if (existingTickets.length > 0) {
						if (existingTickets.length === 1 && existingTickets[0].seatingInfo?._type === 7 && existingTickets[0].amount === ticket.amount) {
							// General admission ticket
							// If there is only one ticket object we need to look at the amount, because for non-seated ticket there will only be on ticket object but with a different amount.
							return [
								...acc,
								...existingTickets
							]
						}
            // We have existing tickets of this type
            if (ticket.amount <= existingTickets.length) {
                // We need same or fewer tickets - keep only what we need
                return [
									...acc,
									...existingTickets.slice(0, ticket.amount)
								]
            } else {
                // We need more tickets - keep all existing and add new ones
                const additionalTicketsNeeded = ticket.amount - existingTickets.length
                const newTickets = Array(additionalTicketsNeeded).fill({
									name: ticket.name,
									price: ticket.price,
									taxRate: ticket.taxRate,
									categoryRef: ticket.categoryRef,
									ticketTypeId: ticket.id,
									amount: 1,
									type: 'ticket'
                })
                
                return [
                    ...acc,
                    ...existingTickets,
                    ...newTickets
                ]
            }
        } else {
            // No existing tickets - create new ones
            const newTickets = Array(ticket.amount).fill({
                name: ticket.name,
                price: ticket.price,
                taxRate: ticket.taxRate,
                categoryRef: ticket.categoryRef,
                ticketTypeId: ticket.id,
                amount: 1,
                type: 'ticket'
            })
            
            return [...acc, ...newTickets]
        }
    }, [])
	
		// Keep existing products as is
		const productCartItems = checkoutProducts || []
	
		const updatedItems = [...ticketCartItems, ...productCartItems]
	
		return updateCheckout(
			_id, 
			secret, 
			activeEvent!._id, 
			updatedItems, 
			shopId, 
			seatingReservationToken, 
			coupons.map(({ code }) => code)
		).then((res) => {
			setCheckout(res.data)
			const shoppingCart = mapShoppingCart(res.data)
			setShoppingCart(shoppingCart)
			// Reset ticket state
			tickets.forEach((ticket) => {
				if (ticket.state === TicketState.UPDATING) {
					changeTicketState(ticket.id, TicketState.INIT)
				}
			})
		}).catch((error) => {
			if (error.response?.data?.message === 'Checkout is already in final state') {
				setCheckoutExpired(true)
			}
			tickets.forEach((ticket) => {
				if (ticket.state === TicketState.UPDATING) {
					changeTicketState(ticket.id, TicketState.RESERVING_FAILED)
				}
			})
		})
	}

	const handleReserveProducts = async () => {
		// We should always have a checkout when calling this.
		const product = products.find((product) => product.state === ProductState.RESERVING || product.state === ProductState.UPDATING)
		if (!product) return
		if (product.state === ProductState.RESERVING || product.state === ProductState.UPDATING) {
			const { _id, secret, items } = checkout!
			const itemForActiveEvent = items.find(({ eventId }: { eventId: string }) => activeEvent!._id === eventId)
			const { shopId, seatingReservationToken, tickets, products: checkoutProducts } = itemForActiveEvent!
			const ticketCartItems = tickets.map(({ _id, amount, ticketTypeId, type, seatingInfo }) => ({
				_id,
				amount,
				ticketTypeId,
				type,
				seatingInfo
			}))
			const productCartItems = products.reduce((acc: any, { amount, name, price, productVariantId, taxRate, isFulfillable }: any) => {
				if (amount === 0) return acc

				const checkoutProduct = checkoutProducts.find((checkoutProduct) => checkoutProduct.productVariantId === productVariantId)
				if (checkoutProduct) {
					return [
						...acc, 
						{
							productVariantId,
							name: checkoutProduct.name,
							amount,
							price,
							taxRate: checkoutProduct.taxRate,
							isFulFillable: checkoutProduct.isFulfillable,
							type: checkoutProduct.type,
							_id: checkoutProduct._id
						}
					]
				} else {
					return [
						...acc, 
						{
							productVariantId,
							name,
							amount,
							price,
							taxRate,
							isFulfillable,
							type: ProductType.product
						}
					]
				}
			}, []) as any

			const updatedItems = []
			if (ticketCartItems.length > 0) updatedItems.push(...ticketCartItems)
			if (productCartItems.length > 0) updatedItems.push(...productCartItems)
			// Map full checkout for adding products
			updateCheckout(_id, secret, activeEvent!._id, updatedItems, shopId, seatingReservationToken, coupons.map(({ code }) => code)).then((res) => {
				setCheckout(res.data)
				const shoppingCart = mapShoppingCart(res.data)
				setShoppingCart(shoppingCart)
			}).catch((error) => {
				if (error.response?.data?.message === 'Checkout is already in final state') {
					// This means the checkout is aborted or completed.
					setCheckoutExpired(true)
				}
				// Reset amount/price of product and set failed state
				changeProductState(product._id, ProductState.RESERVING_FAILED)
			})
		}
	}

	const handleCoupons = async () => {
		const { _id, secret, items } = checkout!
		const itemForActiveEvent = items.find(({ eventId }: { eventId: string }) => activeEvent!._id === eventId)
		if (!itemForActiveEvent) return

		// Compare current coupons with applied coupons
		const currentCouponCodes = coupons.map(({ code }) => code).sort()
		const appliedCouponCodes = (itemForActiveEvent.appliedCoupons || [])
			.map(coupon => typeof coupon === 'string' ? coupon : coupon.code)
			.sort()

		// If coupons are the same, no need to update
		if (JSON.stringify(currentCouponCodes) === JSON.stringify(appliedCouponCodes)) {
			return
		}

		const { shopId, seatingReservationToken, tickets, products: checkoutProducts } = itemForActiveEvent!
		const ticketCartItems = tickets.map(({ _id, amount, ticketTypeId, type, seatingInfo }) => ({
			_id,
			amount,
			ticketTypeId,
			type,
			seatingInfo
		}))
		const productCartItems = products.reduce((acc: any, { amount, name, price, productVariantId, taxRate, isFulfillable }: any) => {
			if (amount === 0) return acc

			const checkoutProduct = checkoutProducts.find((checkoutProduct) => checkoutProduct.productVariantId === productVariantId)
			if (checkoutProduct) {
				return [
					...acc, 
					{
						productVariantId,
						name: checkoutProduct.name,
						amount,
						price,
						taxRate: checkoutProduct.taxRate,
						isFulFillable: checkoutProduct.isFulfillable,
						type: checkoutProduct.type,
						_id: checkoutProduct._id
					}
				]
			} else {
				return [
					...acc, 
					{
						productVariantId,
						name,
						amount,
						price,
						taxRate,
						isFulfillable,
						type: ProductType.product
					}
				]
			}
		}, []) as any

		const updatedItems = []
		if (ticketCartItems.length > 0) updatedItems.push(...ticketCartItems)
		if (productCartItems.length > 0) updatedItems.push(...productCartItems)
		// Map full checkout for adding products
		updateCheckout(_id, secret, activeEvent!._id, updatedItems, shopId, seatingReservationToken, coupons.map(({ code }) => code)).then((res) => {
			setCheckout(res.data)
			const shoppingCart = mapShoppingCart(res.data)
			setShoppingCart(shoppingCart)
		}).catch((error) => {
			if (error.response?.data?.message === 'Checkout is already in final state') {
				// This means the checkout is aborted or completed.
				setCheckoutExpired(true)
			}
		})
	}

	useEffect(() => {
		if (checkout) {
			handleCoupons()
		}
	}, [coupons])

	useEffect(() => {
		handleReserveProducts()
	}, [products])

	useEffect(() => {
		if (event) {
			handleReserve(event._id, event.tickets)
		}
	}, [event, event?.tickets])
}
