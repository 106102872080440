import axios from 'axios'
import { ApiEvent, ApiCheckout } from './types/api-types'

/** QUERIES */
export const fetchEventInfo = async (eventId?: string) =>
	axios<ApiEvent>(`${process.env.REACT_APP_API_ENDPOINT}/events/info/${eventId}`)

export const fetchCheckout = async (checkoutId: string, secret: string) =>
	axios<ApiCheckout>(`${process.env.REACT_APP_API_ENDPOINT}/checkout/${checkoutId}?secret=${secret}`)

export const fetchProducts = async (productStreamId: string) => 
	axios(`${process.env.REACT_APP_API_ENDPOINT}/products/streams/${productStreamId}/products`)

export const fetchPromotionCode = async (code: string, sellerId: string, eventId: string) =>
	axios(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${code}/info?sellerId=${sellerId}&eventId=${eventId}`)

/** MUTATIONS */
export const createCheckout = async (shopId: string | null, eventId: string, selectedTickets: { amount: number; ticketTypeId: string }[], coupons: string[]) =>
	axios.post<ApiCheckout>(`${process.env.REACT_APP_API_ENDPOINT}/checkout`, {
		...(coupons.length > 0 ? { coupons } : {}),
		eventId,
		type: 'transaction',
		items: selectedTickets,
		...(shopId ? { shopId } : {})
	})

export const bas = async (
	sellerId: string,
	shopId: string | null,
	checkoutId: string | undefined,
	secret: string | undefined,
	eventId: string,
	ticketTypeId: string,
	amount: number,
	appliedCoupons: string[],
	conditionalTickets?: Array<{ ticketTypeId: string, amount: number }>
) => {
	return axios.post(`${process.env.REACT_APP_BAS_ENDPOINT}`, {
		sellerId,
		shopId,
		checkoutId,
		secret,
		eventId,
		ticketTypeId,
		amount,
		appliedCoupons,
		...(conditionalTickets ? { conditionalTickets } : {})
	})
}
	

export const updateCheckout = async (
	checkoutId: string,
	secret: string,
	eventId: string,
	items: any,
	shopId?: string,
	seatingReservationToken?: string, 
	coupons?: string[]
) => {

	return axios.post(
		`${process.env.REACT_APP_API_ENDPOINT}/checkout/${checkoutId}/items`,
		{
			secret,
			eventId,
			items,
			...(seatingReservationToken ? { seatingReservationToken } : {}),
			...(shopId ? { shopId } : {}),
			...(coupons?.length ?? 0 > 0 ? { coupons } : {})
		}
	)
}
	
