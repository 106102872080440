import { ApiEventTicketGroup, ApiEventTicketRule, PriceType, ProductType } from './api-types'

enum TicketState {
  INIT = 'INIT',
  RESERVING = 'RESERVING',
  RESERVING_FAILED = 'RESERVING_FAILED',
  UPDATING = 'UPDATING',
  UPDATING_FAILED = 'UPDATING_FAILED'
}

enum ProductState {
  INIT = 'INIT',
  RESERVING = 'RESERVING',
  RESERVING_FAILED = 'RESERVING_FAILED',
  UPDATING = 'UPDATING',
  UPDATING_FAILED = 'UPDATING_FAILED'
}

type TicketFeeRule = {
  fix: number
  var: number
}

type TicketFee = {
  enabled: boolean
  name: string
  type: string
  rules: TicketFeeRule[]
}

type Ticket = {
  id: string
  name: string
  price: number
  amount: number
  taxRate: number
  description?: string
  active: boolean
  categoryRef: string
  // dynamicFees: TicketFee[]
  minAmountPerOrder: number
  state: TicketState
  styleOptions: {
    hiddenInSelectionArea?: boolean
    thumbnailImage?: string
  },
  conditionalAvailability: boolean,
  conditionalAvailabilityMode: string,
  rules: ApiEventTicketRule[],
  v: number
}

type TicketCategory = {
  listWithoutSeats?: boolean
  maxAmountPerOrder?: number
  availabilityIndicator?: string
  name: string
  ref: string
  v: number
}

type FixedPriceProduct = {
  _id: string
  name: string
  image: string
  description: string
  price: number
  compareAtPrice: number | null
  amount: number
  state: ProductState
  productVariantId: string
  taxRate: number
  taxable: boolean
  isFulfillable: boolean
  type: ProductType
  priceType: PriceType.fixed
}

type RangePriceProduct = {
  _id: string
  name: string
  image: string
  description: string
  price: number
  compareAtPrice: number | null
  amount: number
  state: ProductState
  productVariantId: string
  taxRate: number
  taxable: boolean
  isFulfillable: boolean
  type: ProductType
  priceType: PriceType.range
  min: number
  max: number
}

type EventLocation = {
  locationCity: string
  locationName: string
  locationPostal: string
  locationStreet: string
}

type Seller = {
  _id: string
  name: string
  customLogo: string
  defaultLanguage: string
  url: string
}

type Event = {
  _id: string
  url: string
  image?: string
  name: string
  description: string
  tickets: Ticket[]
  timezone: string
  start: string
  accentColor: string
  location: EventLocation
  saleStatus: string
  seller: Seller
  startingPrice: number
  hideStartingPrice: boolean
  categories: TicketCategory[]
  showAvailabilityIndicator: boolean
  min: number
  max: number
  upsellSettings: {
    active: boolean
    productStream?: string
    headerImage?: string
    crossSells: {
      eventIds: string[]
    }
    _id: string
  },
  groups: ApiEventTicketGroup[]
}

type ShoppingCartItemTicketSeatingInfo = {
  _id: string
  rowName: string
  seatName: string
  sectionName: string
  groupName?: string
  name?: string
  _type: number
}

type ShoppingCartItemTicket = {
  ticketTypeId: string
  name: string
  price: number
  amount: number
  seatingInfo?: ShoppingCartItemTicketSeatingInfo
}

type ShoppingCartItemProduct = {
  _id: string
  amount: number
  name: string
  price: number
}

type ShoppingCartItem = {
  _id: string
  eventId: string
  innerCharge: number
  outerCharge: number
  regularPrice: number
  tickets: ShoppingCartItemTicket[]
  products: ShoppingCartItemProduct[]
}

type ShoppingCart = {
  _id: string
  createdAt: string
  expiresAt: string
  realPrice: number
  secret: string
  status: string
  items: ShoppingCartItem[]
}

export { TicketState, ProductState }
export type {
  Event,
  Ticket,
  TicketFee,
  TicketFeeRule,
  EventLocation,
  TicketCategory,
  ShoppingCart,
  ShoppingCartItem,
  ShoppingCartItemTicket,
  ShoppingCartItemProduct,
  FixedPriceProduct,
  RangePriceProduct
}
